
import React from "react"
import purple from '@material-ui/core/colors/purple'
/**
 * Define app styles here
 * these styles will de defaults for theme provided to createMuiTheme API
 */
const theme =  {
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: 'roboto',
        fontWeightMedium: 500,
        body1: {
            // Styles inside body1 will be inherited by all the children
            fontSize: 13,
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
        },
        subtitle1: {
            fontSize: 12,
        },
        button: {
            fontStyle: 'normal',
        },
        span: {
            fontSize: 60
        },
    },
    // palette: {
    //     // primary: purple,
    //     secondary: {
    //         main: '#f44336',
    //     },
    //     //   color: {
    //     //     paper: 'purple'
    //     //   },
    //     //   text: {
    //     //       primary: 'purple'
    //     //   }
    //     primary: {
    //         main: "#4133ff",
    //         light: "rgb(101, 115, 195)",
    //         dark: "rgb(44, 56, 126)",
    //         contrastText: "#fff",
    //     },
    // },
    shape: {
        borderRadius: 3
    },
    
    // FIXME: snackbar 
    zIndex: {
        modal: 1300,
        snackbar: 1400,
        drawer: 1,
        appBar: 1100,
        mobileStepper: 1000,
        tooltip: 1500,
    },
    
    palette: {
        secondary: {
            main: '#766dff',
        },
        //   color: {
        //     paper: 'purple'
        //   },
        //   text: {
        //       primary: 'purple'
        //   }
        primary: {
            main: "#4133ff",
            light: "rgba(65, 51, 255, 0.24)",
            dark: "#0d00ca",
            contrastText: "#fff",
        },
      // type: 'dark', // Switching the dark mode on is a single property value change.
    },
    // Override Material components css here
    // NOTE: these are applied as global css
    overrides: {
        // Style sheet name ⚛️
        MuiInputBase: {
        // Name of the rule
            input: {
                fontSize: 14
            },

            root: {
                color: 'inherit'
            }
        },
        MuiButton: {
            root: {
                color: '#5E5E5E'
            }
        },
        
        MuiPaper: {
            root: {
                color: 'inherit'
            }
        },

        MuiTableCell: {
            body: {
                color: 'inherit'
            },
            head: {
                fontSize: 15,
                fontWeight: 700
            }
        },

        MuiFormLabel: {
            root: {
                color: 'inherit'
            }
        },

        MuiTab: {
            wrapper: {
                fontWeight: 700
            },
        }
    }
  }

export default theme